import React from 'react'
import { NavLink } from 'react-router-dom'

const Footer = () =>{
    return (
        <>
        <section className="footer-col" id="footer-col">
          <div className="container">
            <div className="row">
              <div className="footer">
                <div className="footer-section-heading">
                  <h2>Explore the IANS</h2>
                </div>
                
                <ul>
                <li><a href="index.html" target="_blank">Home</a></li>
                <li><a href="https://ians.in/" target="_blank">IANS English News</a></li>
                <li><a href="https://ianshindi.in/" target="_blank">IANS Hindi News</a></li>
                <li><a href="https://www.iansphoto.in/" target="_blank">IANS Photo</a></li>
                <li><a href="https://www.iansvideo.com/" target="_blank">IANS Video</a></li>
                <li><a href="index.html" target="_blank">IANS TV</a></li>
                <li><a href="https://ianslife.in/" target="_blank">IANS Life</a></li>
                <li><a href="https://ianslive.in/" target="_blank">IANS Live</a></li>
                <li><a href="http://ianswellness.in/" target="_blank">IANS Wellness</a></li>
                <li><a href="https://bollywoodcountry.in/" target="_blank">Bollywood Country</a></li>
                <li><a href="https://www.cricketfanatic.in/" target="_blank">Cricket Fanatic</a></li>
                <li><a href="#" target="_blank">Khabar Filmy</a></li>
                </ul>
                <p>Copyright © 2022 Indo-Asian News Service (IANS) India Private Limited. All Rights Reserved. Web Design by: <a href="http://ianssoftware.com/" target="_parent" title="IANS Software Services">IANS Software Services</a></p>
              </div>
            </div>
          </div>
        </section>     
        </>
    )
}
export default Footer