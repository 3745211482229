import React from 'react'
import Slider from "react-slick"
import axios from 'axios';
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import {NavLink} from 'react-router-dom';
import ModalVideo from './SearchModal';
export default function PortfolioPageSlider(props) {
    var settings = {

      autoplay: false,
      autoplaySpeed: 1000,
      slidesToShow: 4,
      dots: true,
      arrows: true,
      responsive: [
     {
       breakpoint: 458,
       settings: {
       slidesToShow: 1,
       slidesToScroll: 1
       }
     }
    ]
      };
      return (
        <>
              <section className="upcoming-meetings" id="latest">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-heading">
                                <h2>{props && props.name ? props.name : ''}</h2>
                            </div>
                        </div>
                        <Slider {...settings} className="slick-slider2">
                          {props.data.map((item, i)=>(
                          <div className="col-lg-3" key={i}>
                            <div className="meeting-item zoom1">
                              <div className="thumb">
                                <img src={item.image} alt="#" />
                                <ModalVideo data={item}/>
                              </div>
                              <div className="down-content">
                                <div className="mvp-cat-date-wrap">
                                  <span className="mvp-cd-cat">{item.created_at}</span>
                                </div>
                                <h4><NavLink to={item.slug}>{item.title}</NavLink></h4>
                              </div>
                            </div>
                          </div>                                         
                          ))}
                        </Slider>
                     </div>
                </div>
            </section>  
        </>

      );
    }
