import Footer from './Footer'
import Header from './Header'
import React,{useState, useEffect} from 'react';
import axios from 'axios';
import {useParams} from 'react-router-dom';
import New from './New';
import {Helmet} from "react-helmet";
import { useHistory } from "react-router-dom";
import { useLocation, Redirect } from 'react-router-dom';

const Login = ()=>{
    const [collectionData, setCollectionData] = useState([]);
    const [errorMsg, setErrorMsg] = useState('');
    const[inputData, setInputData] = useState({
        username:'',
        password:''
    })
    const history = useHistory();
    const location = useLocation();

    const { from } = location.state || { from: { pathname: '/' } };

      const handleOnChange = event => {
        const { name, value } = event.target;
        setInputData({ ...inputData, [name]: value });
      };
        const loginUser = (e) => {


        e.preventDefault()
        if(!inputData.username){
            setErrorMsg('Email can not be blank');
        }else if(!inputData.password){
            setErrorMsg('Password can not be blank');

        }else{
            let formData = new FormData();

            formData.append('username', inputData.username);
            formData.append('password', inputData.password);
            axios.post(`https://cms.iansnews.in/api/login/`,inputData,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
            // const latestStories =
            //     res.data.results &&
            //     res.data.results.length > 0 &&
            //     res.data.results.slice(0, 8).map((item) => {
            //     return {
            //         byline: item.byline,
            //         content: item.short_desc,
            //         id: item.id,
            //         image: item.thumbnail,
            //         title: item.title,
            //         slug:'/'+item.slug,
            //         image_caption: item.image_caption,
            //     };
            //     });
            // setLatestData(latestStories);
            if(response && response.data && response.data.status){
                localStorage.setItem('accessToken', response.data.token? response.data.token :'');
                localStorage.setItem('userName', response.data.name? response.data.name:'');
                if(localStorage && localStorage.redirectTo){
                    history.push(localStorage.redirectTo);

                }else{
                    history.push('/');

                }
            }

            console.log(response, 'rrrrrrrrrrr');
                        setErrorMsg(response.data.message);

            }).catch(err =>{
                console.log(err,'eeeee');
                setErrorMsg(err.response.data.message);
            });            
            // const { name, email, message } = e.target.elements
            // let conFom = {
            //   name: name.value,
            //   email: email.value,
            //   message: message.value,
            // }
        }

        // console.log(conFom)
      }
      useEffect(() => {
        if(localStorage && localStorage.accessToken){
            history.push('/');
        }
        <Redirect to={from} />
      }, []);
    return(
        <>
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <link rel="icon" href="icon-new.ico" type="image/x-icon" />
          <link rel="shortcut icon" href="/theme/bollywood/images/favicon.ico" type="image/x-icon" />
          <title>Bollywoodcountry.in -{collectionData.title ? collectionData.title.toUpperCase() : ''}</title>
          <meta name="keywords" content={collectionData.keywords ? collectionData.keywords.toUpperCase() : ''} />
          <meta name="description" content="Stay up-to-date with the latest Bollywood news and updates. Get the hottest gossips, rumors, movie reviews, and exclusive celebrity interviews from the captivating world of Hindi cinema. Your one-stop destination for everything Bollywood." />
        </Helmet>
        <Header />
        <section className="upcoming-meetings" id="meetings">
            <div className="container py-5 h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                        <div className="card bg-dark text-white" style={{borderRadius: "1rem"}}>
                            <div className="card-body p-5 text-center">
                                <div className="mb-md-5 mt-md-4 pb-5">
                                    <h2 className="fw-bold mb-2 text-uppercase">Login</h2>
                                    {errorMsg}

                                    <p className="text-white-50 mb-5">Please enter your login and password!</p>
                                    <form onSubmit={loginUser} >
                                        <div className="form-outline form-white mb-4">
                                            <input type="text" placeholder="Username" name="username" onChange={handleOnChange} value={inputData.username} className="form-control form-control-lg" />
                                        </div>
                                        <div className="form-outline form-white mb-4">
                                            <input type="password" placeholder="Password" name="password" onChange={handleOnChange} value={inputData.email} className="form-control form-control-lg" />
                                        </div>
                                        <div className="form-outline form-white mb-4">
                                        </div>
                                        <div className="form-outline form-white mb-4">
                                            <button className="btn btn-outline-light btn-lg px-12" type="submit">Login</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>	
            </div>			
        </section>
        <Footer />        
        </>
    )
}
export default Login