import React,{useState, useEffect} from 'react';
import FileSaver from "file-saver";
import {NavLink} from 'react-router-dom';
import ModalVideo from './SearchModal';
import PortfolioPageSlider from './PortfolioPageSlider';


const HomeCat=(props)=>{
    const [videoUrl, setVideoUrl] = useState('');
    const [autoPlayStatus, setAutoPlayStatus] = useState(true);

    const videoStartPlay = (url)=>{
        setVideoUrl(url);
        // var modifiedUrl = 'videos/' + url.split('/').pop();
        // downloadResource(url, modifiedUrl);
        setAutoPlayStatus(true);
    }

    // var url = response.data[0].video_file;
    //       var modifiedUrl = 'videos/' + url.split('/').pop();
    //       $("[data-dwl-file]").append(`
                    
    //       <a id="lnk" onClick="downloadResource('${modifiedUrl}');">download full video  <i class="fa fa-download" aria-hidden="true"></i></a>

    //       `);

          
    const downloadResource=(url, filename)=> {
        if (!filename) filename = url.split('\\').pop().split('/').pop();
        // fetch('https://cms.iansnews.in/api/get_presigned_url/?object_key=' + encodeURIComponent(url))
        //   .then(response => response.json())
        //   .then(data => {
        //   if (data.error) {
        //     console.error(data.error);
        //     return;
        //   }
        //   let presignedUrl = url;
          downloadFile(url, filename);
        //   })
        //   .catch(e => console.error(e));
        }
        
    const downloadFile=(url, filename)=> {
        fetch(url)
        .then(response => response.blob())
        .then(blob => {
            const fileSaver = FileSaver.saveAs(blob, filename);
            fileSaver.onwriteend = function () {
            console.log('Download completed.');
            };
            fileSaver.onerror = function () {
            console.error('Download failed.');
            };
        })
        .catch(e => console.error(e));
    }
    <script dangerouslySetInnerHTML={{
        __html: `
    $(document).ready(function () {
        
        $("#${props.crow}").carousel({ interval:false });           
       
        $(".left").click(function () {
            $("#${props.crow}").carousel("prev");
        });
        $(".right").click(function () {
            $("#${props.crow}").carousel("next");
        });
        `}} />
    return(
        <>
            <section className="upcoming-meetings" id="latest">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-heading">
                                <h2>{props && props.name ? props.name : ''}</h2>
                            </div>
                        </div>
                        
                        <div className="modal fade" id="myModal" role="dialog">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                <div class="modal-header">
                                    <button type="button" onClick={()=>{
                                        setAutoPlayStatus(false)
                                    }}class="close" data-dismiss="modal">&times;</button>
                                    {/* <ModalVideo /> */}
                                    </div>
                                    <div className="modal-body">
                                    <video controlsList="nodownload" autoPlay src={videoUrl}>
                                        <source src={videoUrl} type="video/mp4"/></video>

                                        {/* <video controls="true" controlslist=" nodownload" autoplay="" muted="" loop="" data-video-file="">
                                        <source src={'https://iansportalimages.s3.amazonaws.com/videos_watermark/260723_NEWS_JUHI%20PARMAR%20PENS%20SCATHING%20POST%20AFTER%20WALKING%20OUT%20OF%20BARBIE%20FILM%20WITH%20DAUGHTER.mp4'} type="video/mp4"/>
                                        </video> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="row">
                                {props && props.data && props.data.length > 0 && props.data.slice(0, 4).map((item, i)=>{
                                    return(
                                        <>
                                            {/* <div className="col-lg-3" key={i}>
                                                <div className="meeting-item zoom1">
                                                    
                                                        <div className="thumb">
                                                        <img src={item.image} alt="#" />

                                                         <ModalVideo data={item}/>
	
                                              
                                                        </div>
                                                        <div className="down-content">
                                                            <div className="mvp-cat-date-wrap">
                                                                <span className="mvp-cd-cat">{item.created_at}</span>
                                                            </div>
                                                            <NavLink to={item.slug}>{item.title}</NavLink><h4></h4>
                                                        </div>
                                                    
                                                </div>
                                            </div>                                         */}

                                            {/* <PortfolioPageSlider data={props.data}/> */}
                                        </>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>         
        </>
    )
}
export default HomeCat;