import React,{useState, useEffect} from 'react';
import {Link, NavLink} from 'react-router-dom'
import SearchModal from './SearchModal';
import Button from 'react-bootstrap/Button';
import { useHistory } from "react-router-dom";

const Header =()=>{
    const [loginStatus, setLoginStatus] = useState(false);
    const locale = 'en';
    const [headCss, setHeaderCss] = useState('0%');
    
    const [today, setDate] = React.useState(new Date()); // Save the current date to be able to trigger an update

    const year = new Date().getFullYear();
    // const day = today.toLocaleDateString(locale, { weekday: 'long' });
    // const date = `${day}, ${today.toLocaleDateString(locale, { month: 'long' })}${today.getDate()},${year} \n\n`;
    const date = `${today.getDate()} ${today.toLocaleDateString(locale, { month: 'long' })}  ${year} \n\n`;

    const hour = today.getHours();
    //const wish = `Good ${(hour < 12 && 'Morning') || (hour < 17 && 'Afternoon') || 'Evening'}, `;
    const time = today.toLocaleTimeString(locale, { hour: 'numeric', hour12: true, minute: 'numeric' });
    const [search, setSearch] = useState();

    const [searchHead, SetSHead] = useState('template-search');
    const inputData=(event)=>{
        setSearch(event.target.value);
      }
      const history = useHistory();
      const getSearchData = () =>{
        // alert('rr');
  
        let path = `/search/`+search;
        //setShow(false);
        history.push(path);
        // window.open("/category/india/"+search)
      }
      const onSubmit = (e) => {
        e.preventDefault()
        // const { name, email, message } = e.target.elements
        // let searchData = {
        //   name: search
       
        // }
        // localStorage.removeItem('searchData');

        // localStorage.clear();

        // localStorage.setItem('searchData', search);

        let path = `/search/` +search;
            SetSHead('template-search')
        history.push(path);

        // console.log(conFom)
      }
      const logoutUser=()=>{
        if(localStorage && localStorage.accessToken){
            localStorage.clear();
            setLoginStatus(false);
            history.push('/login');

        }
      }
      useEffect(() => {
        if(localStorage && localStorage.accessToken){
            setLoginStatus(true);
        }
      }, []);
    return (
        <>
            <header className="header-area header-sticky">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <nav className="main-nav">
                                <NavLink className="tv-logo" to="/">
                                    <img src="/assets/images/IANSLOGOWhite.png" alt="" />
                                </NavLink>
                                <ul className="nav">
                                    <li><NavLink to="/">Home</NavLink></li>
                                    <li><NavLink to="/category/national">General</NavLink></li>
                                    <li><NavLink to="/category/politics">Politics</NavLink></li>
                                    <li><NavLink to="/category/entertainment">Entertainment</NavLink></li>
                                    {/* <li><NavLink to="/category/General">General</NavLink></li> */}
                                    <li><NavLink to="/category/science-tech">Science/Tech</NavLink></li>
                                    <li><NavLink to="/category/sports">Sports</NavLink></li>
                                    <li><NavLink to="/category/specials">Specials</NavLink></li>
                                    <li className="cbx-search" >
                                        <a href="#" onClick={()=>{
                                            setHeaderCss(headCss == '100%' ? '0%':'100%')
                                        }}>
                                        <span> <i className="fa fa-search" aria-hidden="true"></i></span>
                                        </a></li>
                                    {!loginStatus &&        
                                   <li className="vlogin">
                                        <NavLink to="/login" title="Login">
                                            <i className="fa fa-lock" aria-hidden="true"></i>
                                        </NavLink>
                                    </li>
                                    }

                                    {loginStatus &&        
                                   <li className="vlogin">
                                        <a href="#" title="Login" onClick={logoutUser}>
                                            <i className="fa fa-unlock" aria-hidden="true"></i>
                                        </a>

                                    </li>
                                      } 
                                       {/* {loginStatus && 
                                    // <div className="user-article-arrow">
                                    // <span><b><i className="fa fa-2 fa-user"></i> Welcome, IANS </b></span>
                                    // </div>
} */}
                                       
                                        <div id="myNav1" className="overlay1" style={{width:headCss}}>
                                        <NavLink to="#" className="closebtn" onClick={()=>{
                                            setHeaderCss('0%')
                                        }}>&times;</NavLink>
                                        <div className="overlay1-content">
                                            <div className="input-group rounded">
                                        <form onSubmit={onSubmit}>
                                            <input type="search" onChange={inputData} name="search" value={search} className="form-control rounded" placeholder="Search" aria-label="Search" aria-describedby="search-addon" />
                                            <button type="submit" className="btn default">
                                            <span className="input-group-text border-0" id="search-addon">
                                            <i className="fa fa-search"></i>
                                            </span></button>
                                            </form>
                                            </div> 
                                        </div>
                                        </div>
                                </ul> 
                                <a className='menu-trigger'>
                                    <span>Menu</span>
                                </a>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}
export default Header;

