import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ModalVideo(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      
        <div className="main-button-red" onClick={handleShow}>
            <div className="mvp-vid-box-wrap-s">
                <i className="fa fa-2 fa-play" aria-hidden="true"></i>
            </div>	
        </div>   

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          {/* <Modal.Title>{props && props.data.title ? props.data.title : ''}</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
        <div className="modal-body">
        <video controlsList="nodownload" autoPlay src={props.data.video_preview}>
            <source src={props.data.video_preview} type="video/mp4"/></video>
        </div>
        </Modal.Body>
        <Modal.Footer>
        <Modal.Title>{props && props.data.title ? props.data.title : ''}</Modal.Title>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalVideo;