import React,{useState, useEffect} from 'react';
import axios from 'axios';
import Header from './Header'
import Footer from './Footer'
import Top from './Top'
import {NavLink} from 'react-router-dom';
import {useParams} from 'react-router-dom';
import Bollywood from './Bollywood'
import {Helmet} from "react-helmet";
import HomeCat from './HomeCat';
import moment from 'moment';
import Slider from './Slider';
import PortfolioPageSlider from './PortfolioPageSlider';

const Home =()=>{
    const [isLoading, setIsLoading] = useState(false);
    const [topData, setTopData] = useState({
        content: '',
        id: null,
        title: '',
        created_at:'',
        video_preview: '',
        slug:''
    });
    const [entertainmentData, setEntertainmentData] = useState([]);

    const [genralData, setGenralData] = useState([]);
    const [polliticsData, setpolliticsData] = useState([]);

    const topSData =()=>{
        axios
        .get(
        `https://cms.iansnews.in/api/elastic/video/list/?top_news=true`
        )
        .then((res) => {
        
            if(res.data.data &&
            res.data.data.length > 0){
                setTopData({
                    content: res.data.data[0].description ? res.data.data[0].description: '',
                    id: res.data.data[0].id ? res.data.data[0].id: '',
                    title: res.data.data[0].title ? res.data.data[0].title: '',
                    video_preview: res.data.data[0].video_preview ? res.data.data[0].video_preview: '',
                    slug:res.data.data[0].slug ? '/'+res.data.data[0].slug: '/'
                });
            }
        });
    }
  
    const getGeneralData =()=>{
        const spotApi = "https://cms.iansnews.in/api/elastic/video/list/?tags=national";
        axios
        .get(
            spotApi
        )
        .then((res) => {
        const bollystr =
            res.data.data &&
            res.data.data.length > 0 &&
            res.data.data.map((item) => {
            return {
                id: item.id,
                image: item.thumbnail,
                title: item.title,
                video_preview: item.video_preview,
                slug:'/'+item.slug,
                created_at:moment(item.created_at).format("MMMM DD, YYYY"),
            };
            });
        setGenralData(bollystr);
        });
    }

    const getPolliticsData =()=>{
        const politicsApi = "https://cms.iansnews.in/api/elastic/video/list/?tags=politics";
        axios
        .get(
            politicsApi
        )
        .then((res) => {
        const pollstr =
        res.data.data &&
        res.data.data.length > 0 &&
        res.data.data.map((item) => {
        return {
            id: item.id,
            image: item.thumbnail,
            title: item.title,
            slug:'/'+item.slug,
            video_preview: item.video_preview,
            created_at:moment(item.created_at).format("MMMM DD, YYYY"),
        };
        });
        setpolliticsData(pollstr);
        });
    }          

    const entertainmentApi =`https://cms.iansnews.in/api/elastic/video/list/?tags=entertainment`; 
    const getEntertainmentData =()=>{
        axios
        .get(
            entertainmentApi
        )
        .then((res) => {
        const entertainmentStories =
            res.data.data &&
            res.data.data.length > 0 &&
            res.data.data.map((item) => {
            return {
                id: item.id,
                image: item.thumbnail,
                title: item.title,
                video_preview: item.video_preview,
                slug:'/'+item.slug,
                created_at:moment(item.created_at).format("MMMM DD, YYYY"),
            };
            });
        setEntertainmentData(entertainmentStories);
        });
    }
   
    useEffect(() => {
        getGeneralData();
        getEntertainmentData();
        getPolliticsData();
        topSData();
      }, []);
       
    return(
        <>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                <link rel="icon" href="icon-new.ico" type="image/x-icon" />
                <link rel="shortcut icon" href="/theme/Ianstv/images/favicon.ico" type="image/x-icon" />
                <title>Ianstv.in - The last word on IANS-TV and Indian movies</title>
                <meta data-meta-title="Ianstv.in" name="keywords" content="Ianstv news, Ianstv updates, Ianstv celebrities, Hindi cinema, Indian film industry, Ianstv gossips, Ianstv rumors, Ianstv releases, Movie reviews, Celebrity interviews" />
                <meta data-meta-title="Ianstv.in" name="description" content="Stay up-to-date with the latest Ianstv news and updates. Get the hottest gossips, rumors, movie reviews, and exclusive celebrity interviews from the captivating world of Hindi cinema. Your one-stop destination for everything Ianstv." />
            </Helmet>
            <Header />
            <section className="section main-banner" id="top" data-section="section1">


                <video  autoPlay muted loop id="bg-video" src={topData.video_preview}><source src={topData.video_preview} type="video/mp4" /></video>

                <div className="video-overlay header-text">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="caption">
                                    <h2>{topData.title}</h2>
                                    <p>{topData.content}</p>
                                    <div className="main-button-red">
                                        <NavLink to={topData.slug}>
                                            <div className="mvp-vid-box-wrap mvp-vid-marg zoom">
                                            <i className="fa fa-2 fa-play" aria-hidden="true"></i>
                                            </div>
                                            <div className="scroll-to-section"><span>Watch now</span></div>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <Slider /> */}
            <PortfolioPageSlider name={"General"} data={genralData}/>
            <PortfolioPageSlider name={"Politics"} data={polliticsData}/>
            <PortfolioPageSlider name={"Entertainment"} data={entertainmentData}/>
     
            <Footer />
        </>
    )
}

export default Home