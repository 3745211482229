import Footer from './Footer'
import Header from './Header'
import React,{useState, useEffect} from 'react';
import axios from 'axios';
import {useParams} from 'react-router-dom';
import New from './New';
import {Helmet} from "react-helmet";
import {NavLink} from 'react-router-dom';
import FileSaver from "file-saver";
import moment from 'moment';
import ModalVideo from './SearchModal';
import { useHistory } from "react-router-dom";

const Detail = () =>{
  const [videoUrl, setVideoUrl] = useState('');
  const[showMsg, setMsg]=useState('');
  const [autoPlayStatus, setAutoPlayStatus] = useState(true);
  const history = useHistory();

  const videoStartPlay = (url)=>{
      setVideoUrl(url);
      // var modifiedUrl = 'videos/' + url.split('/').pop();
      // downloadResource(url, modifiedUrl);
      setAutoPlayStatus(true);
  }
const {newsId} = useParams();
const [isLoading, setIsLoading] = useState(false);
const [catSlug, setCatSlug] = useState('');
const [cateName, setCatName] = useState('');
const [paramValue, setParamValue] = useState(null);
const [collectionData, setCollectionData] = useState([]);
const [latData, setLatestData] = useState([]);
const [categoryData, setCategoryData] = useState([]);
const LatestData =()=>{
   axios
   .get(
   `https://cms.iansnews.in/api/elastic/video/list/?top_news=true`)
.then(res => {
   const latestStories =
       res.data.data &&
       res.data.data.length > 0 &&
       res.data.data.map((item) => {
       return {
           content: item.short_desc,
           id: item.id,
           image: item.thumbnail,
           title: item.title,
           slug:'/'+item.slug,
           video_preview:item.video_preview,

           created_at:moment(item.created_at).format("MMMM DD, YYYY"),
           image_caption: item.image_caption,
       };
       });
   setLatestData(latestStories);
   });
}
const videoDwlUrl=(url)=>{
 setIsLoading(true);
          let modifiedUrl = 'videos/' + url.split('/').pop();
        // downloadResource(url, modifiedUrl);
        if (!modifiedUrl) modifiedUrl = url.split('\\').pop().split('/').pop();
        let updateUrl = url.replace("videos_watermark", "videos");
        fetch(updateUrl)
        .then(response => response.blob())
        .then(blob => {
          console.log('ÍANSSS',blob);
          if(blob && blob.size && blob.size > 1000){
            setIsLoading(false);
          }
            const fileSaver = FileSaver.saveAs(blob, modifiedUrl);
            fileSaver.onwriteend = function () {
            console.log('Download completed.');
            // alert();
           setIsLoading(false);
            };
            fileSaver.onerror = function () {
            console.error('Download failed.');
            };
        })
        .catch(e => console.error(e));
       
}
const downloadResource=(url, filename)=> {
 
  // fetch('https://cms.iansnews.in/api/get_presigned_url/?object_key=' + encodeURIComponent(url))
  //   .then(response => response.json())
  //   .then(data => {
  //   if (data.error) {
  //     console.error(data.error);
  //     return;
  //   }
  //   let presignedUrl = url;
      //       var modifiedUrl = 'videos/' + url.split('/').pop();
let updateUrl = url.replace("videos_watermark", "videos");
    downloadFile(updateUrl, filename);
  //   })
  //   .catch(e => console.error(e));
  }
const downloadFile=(url, filename)=> {
  fetch(url)
  .then(response => response.blob())
  .then(blob => {
      const fileSaver = FileSaver.saveAs(blob, filename);
      fileSaver.onwriteend = function () {
      console.log('Download completed.');
      };
      fileSaver.onerror = function () {
      console.error('Download failed.');
      };
  })
  .catch(e => console.error(e));
}
const getCategoryData = async(catug) =>{
  axios
  .get(
  `https://cms.iansnews.in/api/elastic/video/list/?tags=${catug}`)
.then(res => {
  const categoryStories =
      res.data.data &&
      res.data.data.length > 0 &&
      res.data.data.map((item) => {
      return {
          content: item.short_desc,
          id: item.id,
          image: item.thumbnail,
          title: item.title,
          slug:'/'+item.slug,
          video_preview:item.video_preview,
          created_at:moment(item.created_at).format("MMMM DD, YYYY"),
          image_caption: item.image_caption,
      };
      });
      setCategoryData(categoryStories);
  });
}
const getData = async() =>{
  try {
    // Get the access token from localStorage
    const accessToken = localStorage.getItem('accessToken');

    // Set the Authorization header with the access token
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,
      },
    };

    // Make the API request using Axios
    const response = await axios.get('https://cms.iansnews.in/api/elastic/video/detail/'+newsId, config);

    // Handle the response data here
    if(response && response.data && response.data.data && response.data.data.length > 0){
      if(response && 
        response.data && 
        response.data.data && 
        response.data.data.length > 0 && 
        response.data.data[0].tags &&
        response.data.data[0].tags.length > 0 &&
        response.data.data[0].tags[0].slug
        ){
          setCatSlug(response.data.data[0].tags[0].slug);
          setCatName(response.data.data[0].tags[0].name);

      }
      // let url = response.data.data[0].video_preview;
  
      //         let modifiedUrl = 'videos/' + url.split('/').pop();
      //     // downloadResource(response.data.data[0].video_preview, modifiedUrl);
      //     if (!modifiedUrl) modifiedUrl = url.split('\\').pop().split('/').pop();
  
  
      // console.log(url,'vvvvvvvvvvvvvvvrt');
      const parsedDate = moment(response.data.data[0].created_at);

      // Format the date as "JANUARY 08, 2023"
      //const formattedDate = parsedDate.format("MMMM DD, YYYY");
 setCollectionData({
    content: response.data.data[0].description,
    // content: new_main_content_string,
    id: response.data.data[0].id,
    image: response.data.data[0].thumbnail,
    title: response.data.data[0].title,
    keywords: response.data.data[0].keywords,
    slug:'/'+response.data.data[0].slug,
    datet:parsedDate.format("MMMM DD, YYYY"),
    // videoUrl:url,
    video_preview:response.data.data[0].video_preview
  })
  setMsg();
  if(response && response.data && response.data.data && response.data.data.length > 0 && response.data.data[0].slug){
    getCategoryData(response.data.data[0].tags[0].slug);

  }
    }

// console.log(response, 'vvvvvvvvvv');
  } catch (error) {
    // Handle errors here
    if(error && error.response.data && error.response.data.detail){
      setMsg(error.response.data.detail);
    }
    // console.error(error);
  }
//   const apiD= `https://cms.iansnews.in/api/elastic/video/detail/${newsId}`
//   axios.get(apiD,{
//     headers: {
//         // 'Content-Type': 'multipart/form-data',
//         dataType: "json",
//         type: "GET",
//         "Cross-Origin-Resource-Policy": "strict-origin-when-cross-origin",

//          Authorization: 'Bearer' + localStorage.accessToken
//     }
// })
// .then(res => {

//   // let main_content_string = res.data.results[0].content;
//   // let iansIndex = main_content_string.indexOf("--IANS") ? main_content_string.indexOf("--IANS") : main_content_string;
//   // let new_main_content_string = main_content_string.substring(0, iansIndex);
//  // console.log(res.data,'vvvvvvvvv');

//   setCollectionData({
//   content: response.data[0].description,
//   // content: new_main_content_string,
//   id: response.data[0].id,
//   image: response.data[0].thumbnail,
//   title: response.data[0].title,
//   keywords: response.data[0].keywords,
//   slug:'/'+response.data[0].slug,
//   video_file:response.data[0].video_file
// })
// })
// .catch(err => { console.log('Google api calendar error', err) })
}
 useEffect(() => {
  if(!localStorage || localStorage.length ==0){
    localStorage.setItem('redirectTo', newsId ? '/'+newsId :'');

    history.push('/login');
}
   setParamValue(newsId)
   LatestData();
   getData();
}, [newsId])
    return(
        <>
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <link rel="icon" href="icon-new.ico" type="image/x-icon" />
          <link rel="shortcut icon" href="/theme/bollywood/images/favicon.ico" type="image/x-icon" />
          <title>IANSTV -{collectionData.title ? collectionData.title.toUpperCase() : ''}</title>
          <meta name="keywords" content={collectionData.keywords ? collectionData.keywords.toUpperCase() : ''} />
          <meta name="description" content={collectionData.content ? collectionData.content.toUpperCase() : ''} />
        </Helmet>
        <Header />
      {!isLoading &&   
        <section className="upcoming-meetings" id="meetings">
          <div className="container">
            <div className="row">
              <div className="cat-section">
              <div className="catmain-heading">	
                <h3>{cateName}{showMsg}</h3>	
              </div>		
              <div className="col-lg-9">
                  <div className="videothumb">
                  <video controls="true" controlsList=" nodownload" autoPlay muted loop src={collectionData.video_preview}>
                    <source src={collectionData.video_preview} type="video/mp4"/>
                  </video>

              </div>
                <div className="video-cat-content">
                  <h4>{collectionData.title}</h4>
                  <div className="video-cat-date-wrap">
                    <span>{collectionData.datet}</span>
                    <span><a href="#" onClick={()=>{
                      videoDwlUrl(collectionData.video_preview)
                    }}><i className="fa fa-download" aria-hidden="true"></i></a></span>
                    <span>{cateName}</span>
                  </div>
                  <p>{collectionData.content}</p>
                </div>
              </div>
              <div className="col-lg-3 padding-right">
              <div className="catsub-heading">
                <h3>More Videos</h3>
              </div>
              <div className="modal fade" id="myModal" role="dialog">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                <div class="modal-header">
                                    <button type="button" onClick={()=>{
                                        setAutoPlayStatus(false)
                                    }}class="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    <div className="modal-body">
                                    <video controlsList="nodownload" autoPlay src={videoUrl}>
                                        <source src={videoUrl} type="video/mp4"/></video>

                                        {/* <video controls="true" controlslist=" nodownload" autoplay="" muted="" loop="" data-video-file="">
                                        <source src={'https://iansportalimages.s3.amazonaws.com/videos_watermark/260723_NEWS_JUHI%20PARMAR%20PENS%20SCATHING%20POST%20AFTER%20WALKING%20OUT%20OF%20BARBIE%20FILM%20WITH%20DAUGHTER.mp4'} type="video/mp4"/>
                                        </video> */}
                                    </div>
                                </div>
                            </div>
                        </div>
              {categoryData && categoryData.length > 0 && categoryData.slice(0,2).map((cateee, c)=>{
                return(
                  <>
                    <div className="cat-item zoom1" key={c}>
                    <a onClick={() => videoStartPlay(cateee.video_preview)} data-toggle="modal" data-target="#myModal">
                      <div className="thumb">
                        <img src={cateee.image} alt="#"/>
                        <ModalVideo data={cateee}/>

                      </div>
                      <div className="down-content">
                        <div className="mvp-cat-date-wrap">
                          <span className="mvp-cd-cat">{cateName}</span>
                        </div>
                        <NavLink to={cateee.slug}><h4>{cateee.title}</h4></NavLink>

                      </div>
                    </a>
                    </div>                  
                  </>
                )
              })}
              </div>		
          	</div>
            </div>
          </div>
        </section>
        }

      {!isLoading &&       
      <section className="upcoming-meetings" id="latest">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="section-heading">
                        <h2>Latest Videos</h2>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="row">
                        {latData && latData.length > 0 && latData.map((latess , s)=>{
                          return(
                            <>
                        <div className="col-lg-4" key={s}>
                          <div className="meeting-item zoom1">
                            {/* <a onClick={() => videoStartPlay(latess.video_preview)} data-toggle="modal" data-target="#myModal"> */}
                              <div className="thumb">
                                <img src={latess.image} alt="#"/>
                                <ModalVideo data={latess}/>
                              </div>
                              <div className="down-content">
                                <div className="mvp-cat-date-wrap">
                                  <span className="mvp-cd-cat">{latess.created_at}</span>
                                </div>
                                <NavLink to={latess.slug}><h4>{latess.title}</h4></NavLink>
                              </div>
                            {/* </a> */}
                          </div>
                        </div>                      
                            </>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
      </section>
              }
        {isLoading && 
         <section className="upcoming-meetings" id="meetings">
            <div className="container">
              <div className="row">
                <h1 className="lod-hed">Downloading....</h1>
                <div className="loader">
                </div>
              </div>
            </div>
         </section>
    }

        <Footer />
        </>
    )
}

export default Detail


