import React,{useState, useEffect, useCallback} from 'react';
import axios from 'axios';
import Footer from './Footer'
import Header from './Header'
import Sidebar from './Sidebar'
import {NavLink} from 'react-router-dom';
import {useParams} from 'react-router-dom';
import New from './New.js'
import {Helmet} from "react-helmet";
import ModalVideo from './SearchModal';
import moment from 'moment';

const Category = ()=>{

const [isLoading, setIsLoading] = useState(false);
const [totNews, setTotNews] = useState(null);
// const [totPages, setPages] = useState(null);
const [cateData, setCateData] = useState([]);
const [latData, setLatestData] = useState({
    id: null,
    title: '',
    byline: '',
    content: '',
    image: '',
    slug:'',
    image_caption: ''
}); 
const [galleryData, setGalleryNews] = useState([]);
const [latestNewsData, setLatestNews] = useState([]);
const [advData, setAdvData] = useState({
        adv_1: '',
        adv_2: ''
});
const {categoryId, subCat, offset} = useParams();
const [currentPage, setCurrentPage] = useState(1);
const [pageList, setPageList] = useState([1,2,3]);
const [lastPage, setLastPage] = useState(1);
const [lt, setLt] = useState(5);
const [requestFlag, setRequestFlag] = useState(true);
const [dat2a, setData] = useState([]);
const [catt, setCatData] = useState(categoryId);
const [subbCatt, setSubCatData] = useState(subCat);
const LatestData =()=>{
    axios
    .get(
    `https://cms.iansnews.in/api/elastic/news/list/?language=english&tags=entertainment`
    )
    .then((res) => {
    const latestStories =
        res.data.results &&
        res.data.results.length > 0 &&
        res.data.results.slice(0, 8).map((item) => {
        return {
            byline: item.byline,
            content: item.short_desc,
            id: item.id,
            image: item.thumbnail,
            title: item.title,
            slug:'/'+item.slug,
            image_caption: item.image_caption,
        };
        });
    setLatestData(latestStories);
    });
}
//alert(categoryId);
const fetchData = async (page) => {
const getApii = `https://cms.iansnews.in/api/elastic/video/list/?tags=${categoryId}&page=${page}`;
   axios.get(getApii)
     .then(res => {

       const capitalizedCatText = categoryId ? categoryId.toUpperCase(): '';
       const capitalizedSubCatText = subCat ? ' / ' + subCat.charAt(0).toUpperCase() +  subCat.slice(1) : '';
//if(lastPage == 1){
setLastPage(res.data.total_pages);

//}
// console.log('xxxxxxx', res);
const getCatData =
res.data.data &&
res.data.data.length > 0 &&
res.data.data.map((item) => {
return {
    byline: item.byline,
    content: item.short_desc,
    id: item.id,
    image: item.thumbnail,
    title: item.title,
    video_preview:item.video_preview,
    slug:'/'+item.slug,
    created_at:moment(item.created_at).format("MMMM DD, YYYY"),

    image_caption: item.image_caption,
};
});
        setCateData(getCatData);
        setLt(res.data.numpages);

     // const paage = [];
     // let x ;
     // for(x = currentPage; x < 5; x++){
     //   paage.push(x);
     //
     //   paage.unshift(pageList[pageList.length-1] +1);
     //
     // }
     // console.log('kk', paage);
     // setPageList(paage);



     setRequestFlag(false);
   })
   .catch(err => {
     // console.log('Google api calendar error', err)
    })

}
useEffect(() => {
  fetchData(currentPage);
  LatestData();

//  .catch(err => { console.log('Google api calendar error', err) })
}, [currentPage, categoryId])

const handleNextPage = () => {
if(lastPage > currentPage){
  setCurrentPage(currentPage + 1);
  let newArry = pageList;
  let newAddArray = pageList[pageList.length-1]+1;
  // if(currentPage > 2){
    let newRemoveArray = pageList[0];
  //
  // }
  newArry.shift(newRemoveArray);
  newArry.push(newAddArray);
    setPageList(newArry);

   }

}

const handlePrevPage = () => {
if(currentPage > 1){

  setCurrentPage(currentPage - 1);
  // setCurrentPage(currentPage + 1);
  let newArry = pageList;

  let newAddArray = pageList[pageList.length-1];
  let newRemoveArray = pageList[0]-1;
    newArry.unshift(newRemoveArray);
  newArry.pop(newAddArray);
setPageList(newArry);
}
}
const handleLastPage = () => {
//if(requestFlag){
  setCurrentPage(lt);
  let lastArrtty = [lt-3, lt-2, lt-1]
  setPageList(lastArrtty);
//  }
}
const handleStartPage = () => {
// if(requestFlag){
  setCurrentPage(1);
  let lastArrtty = [1, 2, 3]
  setPageList(lastArrtty);
//  }
}
const handleSetPage = (page) => {
//  if(requestFlag){
  setCurrentPage(page);
//}
}

    return(
        <>
        <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
            <link rel="icon" href="icon-new.ico" type="image/x-icon" />
            <link rel="shortcut icon" href="/theme/IANSTV/images/favicon.ico" type="image/x-icon" />
            <title>IANSTV.in -{categoryId ? categoryId.toUpperCase() : ''} NEWS</title>
            <meta name="keywords" content="IANSTV news, IANSTV updates, IANSTV celebrities, Hindi cinema, Indian film industry, IANSTV gossips, IANSTV rumors, IANSTV releases, Movie reviews, Celebrity interviews" />
            <meta name="description" content="Stay up-to-date with the latest IANSTV news and updates. Get the hottest gossips, rumors, movie reviews, and exclusive celebrity interviews from the captivating world of Hindi cinema. Your one-stop destination for everything IANSTV." />
        </Helmet>
        <Header />
        <section className="upcoming-meetings cat-page" id="latest">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="catmain-heading">
                          <h3>{categoryId ? categoryId.toUpperCase(): ''}</h3>
                        </div>				
                        {/* <div className="back-button">
                            <a onClick="history.back()" className="label">BACK</a>				
                        </div> */}
                    </div>
                    <div className="col-lg-12">
                        <div className="row">
                            {cateData && cateData.length > 0 && cateData.map((cate, c)=>{
                                return(
                                    <>
                                        <div className="col-lg-4" key={c}>
                                            <div className="meeting-item zoom1">
                                                <div className="thumb">
                                                    <img src={cate.image} alt="#" />
                                                    <ModalVideo data={cate}/>
                                                </div>
                                                <div className="down-content">
                                                    <div className="mvp-cat-date-wrap">
                                                        <span className="mvp-cd-cat">{cate.created_at}</span>
                                                    </div>
                                                    <NavLink to={cate.slug}><h4>{cate.title}</h4></NavLink>
                                                </div>
                                            </div>
                                        </div>                                   
                                    </>
                                )
                            })}

                                    <div className="pagination">
                                        <ul>
                                            {currentPage == 1  && <li onClick={handleStartPage} disabled={currentPage === 1}><NavLink className="prevnext disablelink" to="#">Previous</NavLink></li>}


                                            {currentPage != 1  &&<li onClick={handlePrevPage} ><a href="#">Previous</a></li>}
                                        {pageList.map((data, i)=>{

                                            return(
                                                                        <li key={i} onClick={()=>{
                                            setCurrentPage(data)}}><a href="#" className={currentPage == data ? 'active' : ''}>{data}</a></li>
                                                        )
                                                    })}

                                            <li onClick={handleNextPage}><a href="#" className="prevnext">next</a></li>
                                        </ul>                                            							
                                    </div>
                            {/* <div className="pagination">
                                <ul>				
                                    <li><a className="prevnext disablelink">Previous</a></li>
                                    <li><a href="category8282.html?Category=Politics&amp;&amp;pg=1%20&amp;&amp;#latest" className="active">1</a></li>
                                    <li><a href="category8d60.html?Category=Politics&amp;&amp;offset=9&amp;&amp;pg=1&amp;&amp;#latest" className=";">...</a></li>
                                    <li><a href="category58e5.html?Category=Politics&amp;&amp;offset=891&amp;&amp;pg=100%20&amp;&amp;#latest">100</a></li>
                                    <li><a className="prevnext" href="category6a4a.html?Category=Politics&amp;&amp;offset=9&amp;&amp;pg=2%20&amp;&amp;#latest">next</a></li>
                                </ul>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
        </>
    )
}
export default Category